import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import MultiSelectDropdown from "../components/MultiSelectDropdown";
import QuestionsOptions from "../components/QuestionsOptions";
import AWS from "aws-sdk";
import S3Image from "../components/S3Image";

function getContentTypeByFile(fileName) {
  const extension = fileName.split(".").pop();
  switch (extension) {
    case "mp4":
      return "video/mp4";
    case "jpg":
    case "jpeg":
      return "image/jpeg";
    case "png":
      return "image/png";
    case "m4a":
      return "audio/mp4";
    default:
      return "application/octet-stream";
  }
}

export default function AddQuestionModal({
  show,
  onHide,
  refresh,
  selectedData,
  selectedQuestion,
  title,
  api,
  successMessage,
}) {
  const s3 = new AWS.S3();
  const [questionName, setQuestionName] = useState("");
  const [answer, setAnswer] = useState("");
  const [questionType, setQuestionType] = useState("");
  const [values, setValues] = useState([]);
  // imageOptions will store answer (index 0) plus additional option images
  const [imageOptions, setImageOptions] = useState([]);
  const [optionType, setOptionType] = useState("");
  const [booleansOptions, setBooleansOptions] = useState([
    { label: "TRUE", value: "TRUE" },
    { label: "FALSE", value: "FALSE" },
  ]);
  const [loader, setLoader] = useState(false);
  const [questionError, setQuestionError] = useState("");
  const [optionError, setOptionError] = useState("");
  const [imageOptionError, setImageOptionError] = useState("");
  const [answerError, setAnswerError] = useState("");
  const [imageQuestion, setImageQuestion] = useState("");
  const [imageQuestionError, setImageQuestionError] = useState("");
  const [optionFile, setOptionFile] = useState(false);
  // New state to track the index of the answer option (if any) in the text options list.
  const [answerOptionIndex, setAnswerOptionIndex] = useState(-1);

  const optionTrueFalse = booleansOptions?.map((ele) => ele.value);

  // When selecting extra option images:
  // • If an answer exists (stored as index 0), then additional images must be at most 3.
  const handleOptionsImagesChange = async (e) => {
    if (e.target.files) {
      setOptionFile(true);
      const files = Array.from(e.target.files);
      // If answer exists, total options = 1 + additional options.
      if (answer && files.length + 1 > 4) {
        alert("You can add a maximum of 3 extra options (4 total including answer).");
        e.target.value = "";
        return;
      } else if (!answer && files.length > 4) {
        alert("You must provide an answer image, and then you can add a maximum of 3 extra options.");
        e.target.value = "";
        return;
      }
      const additionalImages = files.map((file) => ({ image: file }));
      if (answer) {
        setImageOptions((prev) => {
          const currentAnswer = prev && prev.length > 0 ? prev[0] : { image: answer };
          return [currentAnswer, ...additionalImages];
        });
      } else {
        setImageOptions(additionalImages);
      }
    }
    setOptionError("");
  };

  const handleUploadImagesOptions = async () => {
    if (!imageOptions || imageOptions?.length === 0) {
      console.error("No files selected");
      return [];
    }
    const uploadPromises = imageOptions.map(async (imageObj, index) => {
      if (imageObj.image) {
        const file = new File([imageObj.image], new Date().getTime());
        const params = {
          Bucket: process.env.REACT_APP_BUCKET_NAME,
          Key: imageObj.image.name,
          Body: file,
        };
        try {
          const data = await s3.upload(params).promise();
          return `https://oceanxs.s3.eu-west-2.amazonaws.com/${data.key}`;
        } catch (error) {
          console.error(`Upload failed for image ${index + 1}:`, error);
          return null;
        }
      } else {
        return imageObj;
      }
    });
    const uploadedImages = await Promise.all(uploadPromises);
    const validImages = uploadedImages.filter((image) => image !== null);
    setImageOptions(validImages);
    return validImages;
  };

  const handleUploadAnswer = async () => {
    if (!answer) {
      console.error("No file selected");
      return null;
    }
    const contentType = getContentTypeByFile(answer?.name);
    const params = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: answer?.name,
      Body: answer,
      ContentType: contentType,
    };
    try {
      const data = await s3.upload(params).promise();
      return data;
    } catch (error) {
      console.error("Upload failed:", error);
      throw error;
    }
  };

  const handleUploadQuestion = async () => {
    if (!questionName) {
      console.error("No file selected");
      return null;
    }
    const contentType = getContentTypeByFile(questionName?.name);
    const params = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: questionName?.name,
      Body: questionName,
      ContentType: contentType,
    };
    try {
      const data = await s3.upload(params).promise();
      return data;
    } catch (error) {
      console.error("Upload failed:", error);
      throw error;
    }
  };

  // This callback is passed to QuestionsOptions.
  // If the removed option is the answer option (tracked via answerOptionIndex), clear the answer input.
  const handleOptionRemove = (index) => {
    setValues((prev) => prev.filter((_, i) => i !== index));
    if (index === answerOptionIndex) {
      setAnswer("");
      setAnswerOptionIndex(-1);
    } else if (index < answerOptionIndex) {
      // Adjust the tracked index if an option before it is removed.
      setAnswerOptionIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (typeof questionName === "string" && questionName.trim() === "") {
      setQuestionError("Question is required");
      return;
    }
    if (questionType === "image" && imageQuestion === "") {
      setImageQuestionError("Image Question is required");
      return;
    }
    if (typeof answer === "string" && answer.trim() === "") {
      setAnswerError("Answer is required");
      return;
    }
    if (optionType === "text" && (values?.length < 2 || values?.length > 4)) {
      setOptionError("You can enter a minimum of 2 and a maximum of 4 text entries");
      return;
    }
    // Duplicate check for text options (including the answer) with case-insensitive comparison
    if (optionType === "text") {
      const normalizedValues = values.map((val) => val.trim().toLowerCase());
      const uniqueValues = new Set(normalizedValues);
      if (uniqueValues.size !== values.length) {
        setOptionError("Duplicate options are not allowed.");
        return;
      }
    }
    if (title !== "Update Question") {
      if (optionType === "image" && (imageOptions?.length < 2 || imageOptions?.length > 4)) {
        setImageOptionError("You can upload a minimum of 2 and a maximum of 4 images (including answer).");
        return;
      }
    } else if (optionType === "image" || questionType === "image") {
      if (
        (imageOptions?.length < 2 || imageOptions?.length > 4) &&
        optionType !== "text" &&
        optionType !== "true/false"
      ) {
        setImageOptionError("You can upload a minimum of 2 and a maximum of 4 images (including answer).");
        return;
      }
    }
    if (optionType === "true/false" && booleansOptions?.length < 2) {
      setOptionError("Boolean options should be exactly two.");
      return;
    }
    setLoader(true);

    try {
      let postData = {};
      let imagesOptionData = [];
      let imagesAnswer = null;
      let imagesQuestion = null;

      if (title === "Update Question") {
        const { _id, isDeleted, updatedAt, createdAt, __v, ...rest } = selectedQuestion;
        if (typeof answer !== "object" && title !== "Update Question") {
          setAnswer(selectedQuestion?.answer);
        }
        postData = { ...rest };
      } else {
        postData = {
          testName: selectedData.testName,
          categoryId: selectedData?.categoryId,
          subCategoryId: selectedData?.subCategoryId,
        };
      }

      if (optionType === "image") {
        if (title !== "Update Question") {
          imagesOptionData = optionFile ? await handleUploadImagesOptions() : imageOptions;
        } else {
          imagesOptionData = imageOptions?.length
            ? await handleUploadImagesOptions()
            : selectedQuestion?.options;
        }
        imagesAnswer =
          typeof answer === "object"
            ? await handleUploadAnswer()
            : selectedQuestion.answer;
      } else {
        // For text options, we now simply use the current values and answer.
        postData.options = values;
        postData.answer = answer;
      }
      if (questionType === "image" && typeof questionName === "object") {
        imagesQuestion = await handleUploadQuestion();
      }
      switch (`${questionType}_${optionType}`) {
        case "text_text":
          postData.question = questionName;
          postData.answer = answer;
          postData.options = values;
          postData.questionType = questionType;
          postData.optionType = optionType;
          break;
        case "text_image":
          postData.question = questionName;
          postData.answer =
            title !== "Update Question"
              ? `https://oceanxs.s3.eu-west-2.amazonaws.com/${imagesAnswer.Key}`
              : typeof imagesAnswer === "object"
                ? `https://oceanxs.s3.eu-west-2.amazonaws.com/${imagesAnswer.Key}`
                : selectedQuestion.answer;
          postData.options = imagesOptionData?.length
            ? imagesOptionData
            : selectedQuestion?.options;
          postData.questionType = questionType;
          postData.optionType = optionType;
          break;
        case "image_image":
          postData.question = imagesQuestion
            ? `https://oceanxs.s3.eu-west-2.amazonaws.com/${imagesQuestion.Key}`
            : selectedQuestion.question;
          postData.answer =
            title !== "Update Question"
              ? `https://oceanxs.s3.eu-west-2.amazonaws.com/${imagesAnswer.Key}`
              : typeof imagesAnswer === "object"
                ? `https://oceanxs.s3.eu-west-2.amazonaws.com/${imagesAnswer.Key}`
                : selectedQuestion.answer;
          postData.options = imagesOptionData?.length
            ? imagesOptionData
            : selectedQuestion?.options;
          postData.imageQuestion = imageQuestion;
          postData.questionType = questionType;
          postData.optionType = optionType;
          break;
        case "image_text":
          postData.question = imagesQuestion
            ? `https://oceanxs.s3.eu-west-2.amazonaws.com/${imagesQuestion.Key}`
            : selectedQuestion.question;
          postData.imageQuestion = imageQuestion;
          postData.answer = answer;
          postData.options = values;
          postData.questionType = questionType;
          postData.optionType = optionType;
          break;
        case "true/false_true/false":
          postData.question = questionName;
          postData.answer = answer;
          postData.options = optionTrueFalse;
          postData.questionType = questionType;
          postData.optionType = optionType;
          break;
        case "image_true/false":
          postData.question = imagesQuestion
            ? `https://oceanxs.s3.eu-west-2.amazonaws.com/${imagesQuestion.Key}`
            : selectedQuestion.question;
          postData.imageQuestion = imageQuestion;
          postData.answer = answer;
          postData.options = optionTrueFalse;
          postData.questionType = questionType;
          postData.optionType = optionType;
          break;
        case "text_true/false":
          postData.question = questionName;
          postData.answer = answer;
          postData.options = optionTrueFalse;
          postData.questionType = questionType;
          postData.optionType = optionType;
          break;
        default:
          console.error("Invalid questionType or optionType");
      }
      if (selectedData.subCategoryName) {
        // postData.subCategoryName = selectedData.subCategoryName;
      }
      if (title === "Update Question") {
        postData.questionId = selectedQuestion?._id;
        if (optionType === "true/false") {
          postData.options = optionTrueFalse;
        }
      }
      delete postData.subCategoryName;
      delete postData.categoryName;
      const response = await api(postData);
      toast.success(successMessage);
      onHide();
      refresh();
    } catch (e) {
      toast.error(e?.response?.data?.message);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (values?.length > 1) {
      setOptionError("");
    }
    if (imageOptions?.length > 1) {
      setImageOptionError("");
    }
  }, [values, imageOptions]);

  const renderInputFields = () => {
    if (questionType === "image") {
      return (
        <>
          <div className="form-group">
            <label className="m-0">Question</label>
            <input
              type="file"
              accept="image/*"
              className="form-control"
              onChange={(e) => {
                setQuestionError("");
                setQuestionName(e.target.files[0]);
              }}
              required={title === "Add Question" ? true : false}
            />
            {questionError && <span className="text-danger">{questionError}</span>}
            {selectedQuestion?.questionType === "image" && (
              <div className="mt-2">
                <S3Image imageKey={selectedQuestion?.question} />
              </div>
            )}
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              value={imageQuestion}
              placeholder="Enter Question"
              onChange={(e) => {
                setImageQuestion(e.target.value);
                setImageQuestionError("");
              }}
            />
          </div>
          {imageQuestionError && <span className="text-danger">{imageQuestionError}</span>}
        </>
      );
    } else if (questionType === "true/false") {
      return (
        <>
          <div className="form-group">
            <label className="m-0">Question</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Question"
              value={questionName}
              onChange={(e) => {
                setQuestionName(e.target.value);
                setQuestionError("");
              }}
            />
          </div>
          {questionError && <span className="text-danger">{questionError}</span>}
        </>
      );
    } else {
      return (
        <>
          <div className="form-group">
            <label className="m-0">Question</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Question"
              value={questionName}
              onChange={(e) => {
                setQuestionName(e.target.value);
              }}
              required
            />
            {questionError && <span className="text-danger">{questionError}</span>}
          </div>
        </>
      );
    }
  };

  const renderOptionAndAnswerFields = () => {
    if (optionType === "image") {
      return (
        <>
          <div className="form-group">
            <label className="m-0">Answer</label>
            <input
              type="file"
              accept=".jpg,.png,.jpeg"
              className="form-control"
              onChange={(e) => {
                const file = e.target.files[0];
                setAnswer(file);
                setAnswerError("");
                // Replace the answer image (index 0) without touching additional options.
                setImageOptions((prev) => {
                  let additional = prev && Array.isArray(prev) ? prev.slice(1) : [];
                  return [{ image: file }, ...additional];
                });
              }}
              required={title === "Update Question" ? false : true}
            />
            {selectedQuestion?.optionType === "image" && (
              <div className="mt-2">
                <S3Image imageKey={selectedQuestion?.answer} />
              </div>
            )}
          </div>
          {answerError && <span className="text-danger">{answerError}</span>}
          <div className="form-group">
            <label className="m-0">Options</label>
            <input
              type="file"
              accept=".jpg,.png,.jpeg"
              multiple
              className="form-control"
              onChange={handleOptionsImagesChange}
              required={title === "Update Question" ? false : true}
            />
            {imageOptionError && <span className="text-danger">{imageOptionError}</span>}
          </div>
          {selectedQuestion?.optionType === "image" && (
            <div className="d-flex justify-content-between">
              {selectedQuestion?.options?.map((img, i) => (
                <div key={i}>
                  <S3Image imageKey={img} />
                </div>
              ))}
            </div>
          )}
        </>
      );
    } else if (optionType === "true/false") {
      return (
        <>
          <div className="form-group">
            <label className="m-0">Answer</label>
            <select
              value={answer}
              className="form-control"
              onChange={(e) => setAnswer(e.target.value)}
              required
            >
              <option value="" disabled>
                Select..
              </option>
              <option value="TRUE">TRUE</option>
              <option value="FALSE">FALSE</option>
            </select>
            {answerError && <span className="text-danger">{answerError}</span>}
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="form-group">
            <label className="m-0">Answer</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Answer"
              value={typeof answer === "string" ? answer : ""}
              onChange={(e) => {
                const newAnswer = e.target.value;
                setAnswer(newAnswer);
                setAnswerError("");
                setValues((prev) => {
                  let updated = [...prev];
                  if (newAnswer) {
                    // If there is no answer option yet, append one.
                    if (answerOptionIndex === -1) {
                      updated.push(newAnswer);
                      setAnswerOptionIndex(updated.length - 1);
                    } else {
                      // Otherwise, update only the answer option without modifying the others.
                      updated[answerOptionIndex] = newAnswer;
                    }
                  } else {
                    // When answer becomes empty, remove the answer option.
                    if (answerOptionIndex !== -1) {
                      updated = updated.filter((_, i) => i !== answerOptionIndex);
                      setAnswerOptionIndex(-1);
                    }
                  }
                  return updated;
                });
              }}
              required
            />
            {answerError && <span className="text-danger">{answerError}</span>}
          </div>
          <div className="form-group">
            <label className="m-0">Options</label>
            <QuestionsOptions values={values} setValues={setValues} onRemove={handleOptionRemove} />
            {optionError && <span className="text-danger">{optionError}</span>}
          </div>
        </>
      );
    }
  };

  useEffect(() => {
    if (title === "Update Question") {
      setQuestionName(selectedQuestion?.question);
      setAnswer(selectedQuestion?.answer);
      setQuestionType(selectedQuestion?.questionType);
      setOptionType(selectedQuestion?.optionType);
      setValues(selectedQuestion?.options);
      if (optionType === "image" || questionType === "image" || questionType === "text") {
        setImageOptions(selectedQuestion?.options);
      }
      setBooleansOptions([
        { label: "TRUE", value: "TRUE" },
        { label: "FALSE", value: "FALSE" },
      ]);
      if (selectedQuestion?.imageQuestion) {
        setImageQuestion(selectedQuestion?.imageQuestion);
      }
    }
  }, [selectedQuestion]);

  useEffect(() => {
    if (title === "Update Question") {
      if (questionType === "image" || questionType === "text") {
        setImageOptions(selectedQuestion?.options);
      }
    }
  }, [questionType]);

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <div className="form-group">
            <label className="m-0">Question Type</label>
            <select
              className="form-control"
              value={questionType}
              onChange={(e) => {
                setQuestionName("");
                setQuestionType(e.target.value);
              }}
              required
            >
              <option value="">Select Question Type</option>
              <option value="text">Text</option>
              <option value="image">Image</option>
              {/* <option value="true/false">True/False</option> */}
            </select>
          </div>
          {renderInputFields()}
          <div className="form-group">
            <label className="m-0">Option Type</label>
            <select
              className="form-control"
              value={optionType}
              onChange={(e) => {
                setOptionType(e.target.value);
                setValues([]);
                setAnswer("");
                setImageOptions("");
                if (e.target.value === "true/false") {
                  setAnswer("");
                }
                // Reset the answer option index for text type.
                setAnswerOptionIndex(-1);
              }}
              required
            >
              <option value="">Select Option Type</option>
              <option value="text">Text</option>
              <option value="image">Image</option>
              <option value="true/false">True/False</option>
            </select>
          </div>
          {renderOptionAndAnswerFields()}
        </Modal.Body>
        <Modal.Footer>
          <button type="submit" className="btn btn-primary">
            {loader ? <Spinner animation="border" size="sm" /> : "Add"}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
