import React from "react";
import { Link, useNavigate } from "react-router-dom";

const PageTitle = ({
  pageHeading,
  motherMenu,
  activeMenu,
  isButton,
  action2,
  action,
  category,
  isBin, setShowModal,
  subCategory
}) => {
  let path = window.location.pathname.split("/");

  const navigate = useNavigate()

  return (
    <div className="page-titles d-flex align-items-start justify-content-between">
      <div>
        <h4>
          {activeMenu}
          {pageHeading}
        </h4>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">{motherMenu}</li>
          {/* <li className="breadcrumb-item active">{activeMenu}</li> */}
          {category?.show && <li className={`breadcrumb-item ${category?.active && 'active'} `} >{category?.name}</li>}
          {subCategory?.show && <li className={`breadcrumb-item ${subCategory?.active && 'active'} `}>{subCategory?.name}</li>}
        </ol>
      </div>
      {isButton ? (
        <div className="">
          <button className="btn btn-sm bTn" onClick={() => action(true)}>
            Add New
          </button>
        </div>
      ) : action2?.length > 1 ? (
        <div className="">
          <Link to="/add-post">
            <button className="btn btn-sm bTn">Add New</button>
          </Link>
        </div>
      ) : null}
      {isBin && (
        <div className="">
          <button className="btn  btn-sm d-flex align-items-center gap-2 cursor-pointer"
            style={{ background: '#FFCCCB' }}
            onClick={() => setShowModal(true)}>
            <i className="fa fa-trash text-danger"></i>
          </button>


        </div>
      )}
    </div>
  );
};

export default PageTitle;
